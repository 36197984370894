<template>
  <div class="group-start" :class="{'disabled': !groupStarted}">
    <div class="group-start__info">
      <div class="group-start__coupon">
        <p>{{$t('group.tipCouponDiscount')}}</p>
        <div class="group-start__coupon--info">
          <p>
            {{groupInfo.discount}}<span>% off</span>
          </p>
          <strong>{{$t('group.labelCouponGroup')}}</strong>
        </div>
        <div class="group-start__coupon--desc" v-if="isLogged && groupStarted">
          <template v-if="groupInfo.count < 10">
            {{$t('group.tipInvitation', {rest: couponTip.rest, discount: couponTip.discount})}}
          </template>
          <template v-else>
            分享團購給更多人吧！
          </template>
        </div>
      </div>
      <div class="group-start__item info">
        <template v-if="$route.query.gid && latestInfo">
          <div class="item-title">{{$t('group.tipGroupEnd')}}</div>
          <div class="group-start__tip">{{$t('group.tipGroupMembers', {num: latestInfo.members.length})}}</div>
          <div class="group-start__list is-clearfix">
            <div class="list-item" v-for="(item, index) in latestInfo.members" :key="index"
                 :style="{backgroundImage: `url(${item.avatar})`}">
              <span v-if="item.isCaptain">{{$t('group.tipGroupHead')}}</span>
            </div>
          </div>
        </template>
        <template v-else>
          <p v-html="$t('group.tipRules', {symbol: symbol, price: groupPrice})"></p>
          <div v-if="isLogged">
            <template v-if="groupStarted">
              <template v-if="groupInfo.isCaptain">
                <button class="btn red" role="button"
                        @click="startShare" >{{$t('group.btnInvivation')}}</button>
                <button class="btn default" v-if="groupInfo.count > 1"
                        role="button" @click="endGroup">{{$t('group.btnEnsureGroup')}}</button>
              </template>
              <template v-else>
                <button class="btn default" role="button"
                        @click="startShare">{{$t('group.btnInvivation')}}</button>
                <button class="btn red" role="button" v-if="!hasJoined" @click="startPay">{{$t('group.btnJoin')}}</button>
              </template>
            </template>
            <template v-else>
              <button class="btn red" role="button" @click="startPay">{{$t('group.btnPayCoupon')}}</button>
              <button class="btn default" role="button" @click="randomJoin">隨機參團</button>
            </template>
          </div>
          <div v-else>
            <button class="btn red" role="button" v-if="!$route.query.gid" @click="startAuthGroup">{{$t('group.btnStartGroup')}}</button>
            <button class="btn red" role="button" @click="startAuthGroup" v-else>{{$t('group.btnJoin')}}</button>
            <button class="btn default" role="button" v-if="groupStarted"
                    @click="startShare">{{$t('group.btnInvivation')}}</button>
          </div>
          <div v-if="isLogged && groupStarted && showMessengerTip" class="messenger-tip">
            <div>{{$t('group.tipGroupMessenger')}}</div>
            <a href="https://m.me/ipastbook?ref=groupbuying">
              <button class="btn red">{{$t('group.btnMessenger')}}</button>
            </a>
          </div>
        </template>
      </div>
      <div class="group-start__item" v-if="groupStarted">
        <div class="item-title" v-if="Number(groupInfo.status) === 2">{{$t('group.tipGroupEnd')}}</div>
        <div class="item-title" v-else>距结束 {{countDown}}</div>
        <div class="group-start__tip">{{$t('group.tipGroupMembers', {num: groupInfo.members.length})}}</div>
        <div class="group-start__list is-clearfix">
          <div class="list-item" v-for="(item, index) in groupInfo.members" :key="index"
               :style="{backgroundImage: `url(${item.avatar})`}">
            <span v-if="item.isCaptain">{{$t('group.tipGroupHead')}}</span>
          </div>
          <div class="list-item add" @click="startShare"></div>
        </div>
      </div>

      <div v-if="!groupStarted">
        <div class="text-center" v-if="$route.query.gid && dataLoaded && latestInfo">
          <button class="btn red" @click="startGroupEvt">{{$t('group.btnStartGroup')}}</button>
        </div>
        <template v-else-if="latestInfoLoaded && latestInfo && isLogged">
          <div class="group-start__title">
            <div>{{$t('group.tipLatestGroup')}}</div>
            <router-link to="/group/record">{{$t('group.tipRecordsEntry')}}></router-link>
          </div>
          <record-item :info="latestInfo"/>
        </template>
        <div class="text-center text-gray" v-if="!latestInfoLoaded">
          <i class="fa fa-spinner fa-spin" style="font-size: 2em;" aria-hidden="true"></i>
        </div>
      </div>
      <div v-else-if="isLogged">
        <div class="text-center" >
          <router-link to="/group/record" style="text-decoration: underline" v-if="isLogged">{{$t('group.tipRecords')}}</router-link>
        </div>
      </div>
    </div>
    <toast :message="loading.text" :value="loading.visible" :lock="true" closeable>
      <div class="loading-icon"></div>
    </toast>
    <modal :footer="false" :show="showShare" @close="showShare = false">
      <div slot="body" class="group-share">
        <div class="payment-tip">{{$t('group.tipShareContent')}}</div>
        <div class="payment-methods">
          <p @click="startInvite">
            <img src="../../../static/icons/group-share_facebook.png" alt="">
            {{$t('group.tipShareFacebook')}}
          </p>
          <p class="copy" :aria-label="shareLink" ref="copy" @click="copyLink">
            <img src="../../../static/icons/group-share_link.png" alt="">
            {{$t('group.tipShareLink')}}
          </p>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { isEmpty, cloneDeep, last } from 'lodash'
import Clipboard from 'clipboard'
import config from '../../app.config'
import { mapGetters, mapActions } from 'vuex'
import currency from '../../mixins/currency'
import FBShare from '../../mixins/FBShare'
import '../../styles/layout/GroupStart.scss'
import RecordItem from '../../components/layout/GroupRecordItem.vue'
import api from '../../api/'
import Cookies from 'js-cookie'
const groupInfo = {
  count: 1,
  discount: 0
}
export default {
  name: 'groupStart',
  mixins: [currency, FBShare],
  components: { RecordItem },
  data: () => ({
    isLogged: true,
    groupStarted: false,
    latestInfoLoaded: false,
    showShare: false,
    showMethods: false,
    dataLoaded: false,
    groupInfo: { ...groupInfo },
    latestInfo: null,
    stripeKey: '',
    order: {},
    countDown: '',
    loading: {
      text: '',
      visible: false
    },
    clipboard: null,
    showMessengerTip: false
  }),
  computed: {
    ...mapGetters(['user']),
    shareLink () {
      const gid = this.groupInfo.groupId || this.$route.query.gid
      let link = `${window.location.origin}/group/start?gid=${gid}`
      if (this.user.uid) {
        link = link + `&ref=${this.user.uid}`
      }
      return link
    },
    // 優惠券提示
    couponTip () {
      if (!this.groupStarted) return config.groupConf[0]
      if (this.groupInfo.count >= last(config.groupConf).num) {
        return { rest: 0, discount: 0 }
      }
      const currentIndex = config.groupConf.reduce((idx, item, index) => {
        if (this.groupInfo.count >= item.num) {
          return index + 1
        }
        return idx
      }, -1)
      return { ...config.groupConf[currentIndex], rest: config.groupConf[currentIndex].num - this.groupInfo.count }
    },
    // 如果当前用户支付可获得折扣
    paidGetDiscount () {
      if (!this.groupStarted) return config.groupConf[0].discount
      const currentIndex = config.groupConf.reduce((idx, item, index) => {
        if (this.groupInfo.count + 1 >= item.num) {
          return index
        }
        return idx
      }, -1)
      return config.groupConf[currentIndex].discount
    },
    hasJoined () {
      if (!this.dataLoaded) return
      let hasJoined = false
      this.groupInfo.members.reduce((flag, item, index) => {
        if (item.uid === this.user.uid) {
          hasJoined = true
        }
      })
      return hasJoined
    }
  },
  mounted () {
    if (!this.user.uid) {
      this.isLogged = false
      // 未登录用户记录邀请人
      const ref = this.$route.query.ref || this.$route.query.sourceId
      if (ref) {
        Cookies.set('sourceId', ref, { expires: 90 })
      }
    }
    this.handleGroupList(this.$route.query)

    this.$nextTick(() => {
      this.clipboard = new Clipboard(this.$refs.copy, {
        text: function (trigger) {
          return trigger.getAttribute('aria-label')
        }
      })
    })
  },
  beforeRouteLeave (to, from, next) {
    if (this.loading.visible) return
    next()
  },
  beforeRouteUpdate (to, from, next) {
    // 路由重载，清理当前定时器
    clearInterval(this.timer)
    this.timer = null
    this.handleGroupList(to.query)
    next()
  },
  methods: {
    ...mapActions(['setLoginShow']),
    // 处理当前团购详情
    handleGroupList (query = {}) {
      const { gid } = query
      if (!gid && !this.isLogged) {
        this.latestInfoLoaded = true
        return
      } // 当前不存在用户，且未提供 groupId，不做请求
      const request = gid
        ? this.isLogged ? api.fetchGroupInfo(gid, this.user.uid) : api.fetchGroupInfo(gid)
        : api.fetchLatestGroup()
      request.then(data => {
        if (data.errors) {
          console.log(data)
          return
        }
        if (isEmpty(data)) return
        const groupInfo = data // 团购详情状态 1、团购进行中 2、团购已关闭 0、团长未支付
        groupInfo.discount = Math.round((1 - groupInfo.discount) * 100)
        if ((groupInfo.status && Number(groupInfo.status) === 2)) { // 用户未登录，当前团已关闭，显示团购信息
          query.gid && (this.groupInfo = groupInfo)
          this.latestInfoLoaded = true
          this.latestInfo = groupInfo
          return
        }
        this.latestInfo = null
        this.groupInfo = groupInfo
        this.groupStarted = true
        !this.timer && this.timeCounter(groupInfo.createdAt)
      }).catch(e => {
        console.log(e)
      }).finally(() => {
        this.dataLoaded = true
        this.latestInfoLoaded = true
      })
    },
    startGroupEvt () {
      this.groupInfo = { ...groupInfo }
      this.$router.push('/group/start')
    },
    timeCounter (createdAt) {
      const moment = window.moment
      const expires = new Date(moment(createdAt).add(2, 'days')).getTime()
      let range = parseInt((expires - new Date().getTime()) / 1000)
      const vm = this
      calcTime()
      this.timer = setInterval(calcTime, 1000)
      return range

      function calcTime () {
        if (range < 1) {
          vm.groupStarted = false
          vm.latestInfo = cloneDeep(vm.groupInfo)
          // vm.groupInfo = {...groupInfo}
          clearInterval(vm.timer)
          vm.timer = null
          return
        }
        range--
        const hours = prefixer(Math.floor(range / 3600))
        const minutes = prefixer(Math.floor(range % 3600 / 60))
        const seconds = prefixer(Math.floor(range % 60))
        vm.countDown = [hours, minutes, seconds].join(':')

        function prefixer (val) {
          return ('0' + val).slice(-2)
        }
      }
    },
    // 未登录用户重定向到团购界面
    startAuthGroup () {
      this.setLoginShow(true)
    },
    // 确认成团
    endGroup () {
      if (this.groupStarted && this.groupInfo.isCaptain) {
        this.$confirm({
          title: this.$t('group.tipConfirmEnd', { count: this.groupInfo.count, discount: this.groupInfo.discount }),
          onOk: (modal) => {
            modal.close()
            const gid = this.$route.query.gid || this.groupInfo.groupId
            api.enSureGroupEnd(gid).then(data => {
              if (data.errors) return
              this.$toast({
                message: '已確認成團'
              })
              this.groupStarted = false
              this.latestInfo = cloneDeep(this.groupInfo)
              this.groupInfo = { ...groupInfo }
            })
          }
        })
      }
    },
    // 分享到Facebook
    startInvite () {
      const gid = this.groupInfo.groupId || this.$route.query.gid
      this.showShare = false
      this.FBShareGroup(gid)
    },
    // 复制链接
    copyLink (e) {
      const vm = this
      this.clipboard.on('success', (e) => {
        vm.$toast({
          message: vm.$t('invitation.tipCopyDone')
        })
        vm.showShare = false
      })
    },
    startShare () {
      this.showShare = true
    },
    // 随机加入团购
    randomJoin () {
      this.loading.visible = true
      const order = {
        payDevice: 'pc'
      }
      api.randomJoinGroup(order).then(data => {
        this.loading.visible = false
        if (!data || data.errors) {
          if (Number(data.errors.code === 44705)) {
            this.$confirm({
              title: '當前暫無團可參加',
              cancel: false
            })
            return
          }
          return this.$toast({
            message: '參團失敗',
            position: 'middle'
          })
        }

        this.$toast({
          message: '參團成功',
          icon: 'fa-check-circle'
        })
        this.checkUserTalked()
        this.handleGroupList(this.$route.query)
      })
    },
    startPay () {
      const order = {
        payDevice: 'pc'
      }
      this.loading.visible = true
      const { gid } = this.$route.query
      const orderForm = Object.assign({}, order, this.order)
      const request = (gid && gid !== String(undefined))
        ? api.joinGroupCheckout(gid, orderForm)
        : api.startGroupCheckout(orderForm)
      request.then(data => {
        this.loading.visible = false
        if (!data || data.errors) {
          if (Number(data.errors.code) === 44701) {
            this.$confirm({
              title: '您已經有一個團正在進行中，不能參與此團',
              cancel: false,
              okText: '查看我的團',
              onCancel: (modal) => {
                this.$router.replace({ path: '/group/start', query: {} })
                modal.close()
              }
            })
            return
          }
          const message = data.errors ? data.errors.message + '<br/>' + '錯誤碼' + data.errors.code : '支付錯誤'
          this.$toast({
            message,
            position: 'bottom',
            duration: 5000
          })
          return
        }

        this.loading.visible = false
        // stripe 本地支付成功处理
        this.$toast({
          message: gid ? '參團成功' : '開團成功',
          icon: 'fa-check-circle'
        })
        this.checkUserTalked()
        this.handleGroupList(this.$route.query)
      }).catch(e => {
        console.log(e)
      })
    },
    // 查看当前用户是否参与messenger聊天
    checkUserTalked () {
      const { talked, uid } = this.user
      if (talked) {
        this.showMessengerTip = !talked
        return
      }
      api.checkUserTalked(uid).then(data => {
        this.showMessengerTip = !data.talked
      })
    }
  }
}
</script>
